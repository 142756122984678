module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eufemia - DNB Design System","short_name":"Eufemia","start_url":"/","icon":"./static/dnb/apple-touch-icon.png","icons":[{"src":"/dnb/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/dnb/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color":"#007272","background_color":"#007272","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"68b678f24da500df7415b27c6c5d34a6"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-scroll-position/gatsby-browser.js'),
      options: {"plugins":[],"elements":[{"selector":"#portal-sidebar-menu","ensureInView":"#portal-sidebar-menu ul li.is-active > .dnb-sidebar-menu__item"}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-eufemia-theme-handler/gatsby-browser.js'),
      options: {"plugins":[],"themes":{"ui":{"name":"DNB"},"eiendom":{"name":"DNB Eiendom"},"sbanken":{"name":"Sbanken (WIP)"}},"filesGlobs":["**/src/style/dnb-ui-core.scss","**/src/style/themes/**/*-theme-{basis,components}.scss","**/src/extensions/payment-card/**/dnb-*.scss"],"includeFiles":["**/dnb-ui-core.*","**/*-theme-components.*","**/*-theme-basis.*","**/payment-card/**/*"],"defaultTheme":"ui","wrapWithThemeProvider":false,"omitScrollBehavior":true,"storageId":"eufemia-theme","themeMatchers":[{}],"inlineDefaultTheme":true,"verbose":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["*.html"],"maximumFileSizeToCacheInBytes":4194304}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
